import { Report } from '@/models/Report';
import { formatDate, currency } from '@/support/String';

export const getReportInfoWindowContent = (marker: any, report: Report) : string => (`
    <table>
      <tr>
        <td colspan="2">
          <span style="font-weight: bold;">Algemeen:</span>
        </td>
      </tr>
      <tr>
        <td>Dossiernummer:</td>
        <td>
          <span style="font-weight: bold;">${report.case_number}</span>
        </td>
      </tr>
      <tr>
        <td>Rapport type:</td>
        <td>${report?.type?.name}</td>
      </tr>
      <tr>
        <td>Adres:</td>
        <td>
          ${report?.address?.street} ${report?.address?.number} ${report?.address?.number_add}<br>
          ${report?.address?.postcode} ${report?.address?.city}
        </td>
      </tr>
      <tr>
        <td colspan="2" style="padding-top: 5px;">
          <span style="font-weight: bold;">Afhandeling:</span>
        </td>
      </tr>
      <tr>
        <td>Bureau:</td>
        <td>${report.organization ? report.organization.name : '-'}</td>
      </tr>
      <tr>
        <td>Opnemer/Deskundige:</td>
        <td>${report.organization_expert ? report.organization_expert.name : '-'}</td>
      </tr>
      <tr>
        <td>Technisch Coordinator:</td>
        <td>${report.organization_tc ? report.organization_tc.name : '-'}</td>
      </tr>
      <tr>
        <td>Schadebedrag Rapport: &nbsp;&nbsp;&nbsp;</td>
        <td>${report?.finance?.payout_total !== null ? currency(report?.finance?.payout_total, 'EUR') : '-'}</td>
      </tr>
      <tr>
        <td colspan="2" style="padding-top: 5px;">
          <span style="font-weight: bold;">Trillingstool:</span>
        </td>
      </tr>
      <tr>
        <td>Max. PGV (1%): &nbsp;&nbsp;&nbsp;</td>
        <td>${report?.pgv_report?.pgv_1pct !== null ? `${report?.pgv_report?.pgv_1pct} mm/s` : '-'}</td>
      </tr>
      <tr>
        <td># bevingen >2.0 mm/s: &nbsp;&nbsp;&nbsp;</td>
        <td>${report?.pgv_report?.accountable_earthquakes !== null ? report?.pgv_report?.accountable_earthquakes : '-'}</td>
      </tr>
      <tr>
        <td colspan="2" style="padding-top: 5px;">
          <span style="font-weight: bold;">Tijdlijn:</span>
        </td>
      </tr>
      <tr>
        <td>Indieningsdatum:</td>
        <td>${formatDate(report.dates.applicant_submitted_at)}</td>
      </tr>
      <tr>
        <td>Opnamedatum:</td>
        <td>${report.dates.planned_at ? formatDate(report.dates.planned_at) : '-'}</td>
      </tr>
      <tr>
        <td>Ondertekeningsdatum:</td>
        <td>${report.dates.approved_at ? formatDate(report.dates.approved_at) : '-'}</td>
      </tr>
      <tr>
        <td>Verstuurd aan aanvrager:</td>
        <td>${report.dates.downloaded_at ? formatDate(report.dates.downloaded_at) : '-'}</td>
      </tr>
      <tr>
        <td colspan="2" style="padding-top: 5px;">
          <span style="font-weight: bold;">Gebouw eigenschappen:</span>
        </td>
      </tr>
      <tr>
        <td>Soort gebouw:</td>
        <td>${report.printAnswer('SoortGebouw')}</td>
      </tr>
      <tr>
        <td>Bouwjaar:</td>
        <td>${report.printAnswer('BouwJaar')}</td>
      </tr>
      <tr>
        <td>Eigendom sinds:</td>
        <td>${report.printAnswer('EigendomSinds') ? formatDate(report.printAnswer('EigendomSinds')) : '-'}</td>
      </tr>
    </table>
    <br>
  `);

export const getEarthquakeInfoWindowContent = (marker: any) : string => (`
    <div class="">
      <div>
        <div>
          <div class="m-2">
            <span style="font-weight: bold;">${marker.name}</span>
          </div>
        </div>
        <div class="m-2">
          <table>
            <tr>
              <td>Magnitude:</td>
              <td>${marker.magnitude}</td>
            </tr>
            <tr>
              <td>Datum:</td>
              <td>${formatDate(marker.date)}</td>
            </tr>
            <tr>
              <td>Binnen gasveld:</td>
              <td>${marker.gasfield ? 'ja' : 'nee'}</td>
            </tr>
            <tr>
              <td>Trillingstool radius: &nbsp;&nbsp;&nbsp;</td>
              <td>${marker.radius} m</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  `);
