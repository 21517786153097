import { Rpc } from '@/models/Rpc';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Report as ReportModel, Report } from '@/models/Report';
import ErrorHandler from '@/support/ErrorHandler';
import { Options } from '@/components/mi-dialog/MiDialog';
import { TableVisibility } from '@/components/data-table/DataTable';
import { formatDate } from '@/support/String';
import Client, { identifyHostname } from '@/support/Client';
import { AxiosError, AxiosResponse } from 'axios';
import { DateFormatter } from '@/support/DateFormatter';
import { Address, Earthquake } from '@/components/PeTAT/PeTAT';
import Storage from '@/support/Storage';
import { organizationUuid } from '@/models/Organization';
import { userRoles } from '@/models/User';
import { getReportInfoWindowContent, getEarthquakeInfoWindowContent } from '@/support/InfoWindow';

@Component<TrillingstoolRealtime>({})
export default class TrillingstoolRealtime extends Vue {
  public $pageTitle = 'Trillingstool Realtime';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  @Prop({ default: true })
  protected allowEdit!: boolean;

  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = false;

  public oAuthServer: string = identifyHostname() || '';

  protected fromDate = new DateFormatter();

  protected toDate = new DateFormatter();

  protected magnitude = '1.8';

  protected earthquakes: Earthquake[] = [];

  protected reports: Report[] = [];

  protected address: Address = {};

  protected showMap = false;

  protected earthquakesGroningerOnly = false;

  protected userRoles = userRoles;

  // other markers
  protected infoWinOpen = false;

  protected infoContent = 'Waarom kan deze text er niet in?';

  protected currentMidx = 0;

  protected infoWindowPos: any = {
    lat: 0,
    lng: 0,
  };

  protected infoOptions: any = {
    pixelOffset: {
      width: 0,
      height: - 35,
    },
  }

  protected showGasfields = true;

  protected gasfields: Gasfield[] = [];

  protected gasfields6Km: Gasfield[] = [];

  protected mapCenter = {
    lat: 53.250748,
    lng: 6.513490,
  };

  // DataTable
  protected visibility: TableVisibility = {
    checkboxes: false,
    title: false,
    search: false,
    limit: false,
  };

  protected async mounted() {
    this.showGasfields = Storage.get('showGasfields') === 'true';
    this.fromDate.selectedDate = '2012-01-01';
    this.toDate.selectedDate = '2022-12-31';
    await this.initializeEarthquakes();
  }

  protected generateAttachment() {
    this.isLoading = true;

    const payload = {
      signature: 'trillingstool:generate-pdf',
      body: {
        report: this.report.uuid,
        from: this.fromDate.selectedDate,
        until: this.toDate.selectedDate,
        minimum_magnitude: this.magnitude,
        has_gasfield: this.earthquakesGroningerOnly,
      },
    };

    new Rpc()
      .rpcPost(payload)
      .then((response: any) => {
        this.$emit('attachmentCreated');
        this.isLoading = false;
        this.$store.dispatch('openDialog', this.dialogOptionsCreated);
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  protected downloadFile() {
    const payload = {
      report: this.report.uuid,
      from: this.fromDate.selectedDate,
      until: this.toDate.selectedDate,
      minimum_magnitude: this.magnitude,
      has_gasfield: this.earthquakesGroningerOnly,
    };

    new ReportModel()
      .getTrillingstoolPdf(payload)
      .then((response: any) => {
        window.open(response.data.url);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get tableOptions(): Object {
    return {
      model: new ReportModel().setTrilingstoolRealtimeEndpoint(this.report.uuid ? this.report.uuid : '').filter({
        gasfield_id: this.earthquakesGroningerOnly ? '1' : null,
        from: this.fromDate.selectedDate,
        until: this.toDate.selectedDate,
      }),
      headers: [
        {
          text: 'datum beving',
          value: 'occurred_at',
          transform: (occurred_at: string) => {
            if (! occurred_at) { return; }

            return formatDate(occurred_at, 'dd-LL-yyyy');
          },
        },
        {
          text: 'gasveld',
          value: 'gasfield_id',
          transform: (gasfield_id: number) => (gasfield_id === 1 ? 'G' : 'A'),
        },
        {
          text: 'epicentrum',
          value: 'city',
        },
        {
          text: 'afstand (km)',
          value: 'distance',
          transform: (distance: number) => {
            const kilometerCalculation = distance / 1000;
            return new Intl.NumberFormat('nl-NL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }).format(kilometerCalculation);
          },
        },
        {
          text: 'magnitude',
          value: 'magnitude',
          class: 'text-right',
          transform: (magnitude: number) => new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }).format(magnitude),
        },
        {
          text: 'PGV 50% (mm/s)*',
          value: 'pgv_50pct',
          class: 'text-right',
          transform: (pgv_50pct: number) => new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(pgv_50pct),
        },
        {
          text: 'PGV 25% (mm/s)**',
          value: 'pgv_25pct',
          class: 'text-right',
          transform: (pgv_25pct: number) => new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(pgv_25pct),
        },
        {
          text: 'PGV 1% (mm/s)***',
          value: 'pgv_1pct',
          class: 'text-right',
          transform: (pgv_1pct: number) => new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }).format(pgv_1pct),
        },
      ],
      actions: [],
    };
  }

  protected toggleGasfields() {
    Storage.set('showGasfield', this.showGasfields ? 'true' : 'false');
  }

  protected async initializeEarthquakes() {
    this.isLoading = true;

    const payload = {
      'filters[from]': this.fromDate.selectedDate,
      'filters[until]': this.toDate.selectedDate,
      'filters[postcode]': this.report.address?.postcode,
      'filters[number]': this.report.address?.number,
      'filters[number_add]': this.report.address?.number_add,
      'filters[magnitude_min]': this.magnitude,
      'filters[magnitude_max]': '10.0',
      'filters[statuses]': ['downloaded'],
      'filters[distance]': {
        latitude: this.report.address?.geolocation?.latitude,
        longitude: this.report.address?.geolocation?.longitude,
        kilometers: 0.5,
      },
      'with[gasfields]': this.showGasfields ? 1 : 0,
      'with[gasfields_6km]': this.showGasfields ? 1 : 0,
      'with[earthquakes]': 1,
      'with[reports]': 1,
      'with[legenda]': 'payout_total',
    };

    Client('get', '/dmz/tools/effectgebied', payload, false, organizationUuid.img)
      .then((response: AxiosResponse) => {
        const address = response.data.address.results[0];
        this.address = address ? address as Address : {};

        this.earthquakes = response.data.earthquakes as Earthquake[];
        this.gasfields = response.data.gasfields as Gasfield[];
        this.gasfields6Km = response.data.gasfields_6km as Gasfield[];
        this.reports = response.data.reports as Report[];
        this.isLoading = false;
        this.showMap = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.earthquakes = [];
        this.isLoading = false;
        this.showMap = false;
      });
  }

  protected getMarkerColor(marker: any) {
    // purple
    // yellow
    // blue
    // white
    // green
    // red
    // black
    // orange
    // gray
    // brown
    if (marker.legenda.payout_total === null || marker.legenda.payout_total < 0) {
      return 'http://labs.google.com/ridefinder/images/mm_20_blue.png';
    }

    const payout_total = marker.legenda.payout_total as number;

    let color = 'white';
    if (payout_total > 100000) {
      color = 'purple';
    } else if (payout_total > 50000) {
      color = 'red';
    } else if (payout_total > 20000) {
      color = 'orange';
    } else if (payout_total > 10000) {
      color = 'yellow';
    } else if (payout_total > 5000) {
      color = 'green';
    } else if (payout_total > 0) {
      color = 'gray';
    } else if (payout_total === 0) {
      color = 'white';
    }

    return `http://labs.google.com/ridefinder/images/mm_20_${color}.png`;
  }

  protected getCircleOptions(earthquake: any) {
    const large = earthquake.magnitude > 2.9;
    const medium = earthquake.magnitude > 1.9 && earthquake.magnitude < 3.0;

    const color = earthquake.gasfield ? 'red' : 'grey';

    let stokeWeight = 1;
    if (medium) {
      stokeWeight = 2;
    }
    if (large) {
      stokeWeight = 3;
    }

    return {
      strokeColor: color,
      strokeOpacity: 1,
      strokeWeight: stokeWeight,
      fillColor: 'orange',
      fillOpacity: 0.05,
    };
  }

  protected async toggleInfoWindow(marker: any, idx: number) {
    if (marker.center) {
      this.infoWindowPos = marker.center;
      this.infoContent = getEarthquakeInfoWindowContent(marker);
    } else {
      this.infoWindowPos = marker.position;
      this.infoContent = await this.getReportInfoWindowContent(marker);
    }

    // check if its the same marker that was selected if yes toggle
    if (this.currentMidx == idx) {
      this.infoWinOpen = ! this.infoWinOpen;
    }
    // if different marker set infowindow to open and reset current marker index
    else {
      this.infoWinOpen = true;
      this.currentMidx = idx;
    }
  }

  protected getEarthquakeMarker(earthquake: any) {
    let marker = 'marker_xs';

    if (earthquake.magnitude > 3.0) {
      marker = 'marker_xl';
    } else if (earthquake.magnitude > 2.4) {
      marker = 'marker_lg';
    } else if (earthquake.magnitude > 1.8) {
      marker = 'marker_sm';
    }

    return `https://tcmg.schadeexpertise-groningen.nl/img/markers/${marker}.png`;
  }

  protected async getReportInfoWindowContent(marker: any) : Promise<string> {
    const report = await this.loadReport(marker.id);

    const windowContent = getReportInfoWindowContent(marker, report);

    return (
      `<div class="">
      <div>
        <div>
          <div class="m-2">
            <span style="font-weight: bold;">${marker.name}</span>
          </div>
        </div>
        <div class="m-2">
          ${windowContent}
        </div>
        <div class="m-2">
          <a href="/reports/${marker.id}" target="_blank">Details</a>
          ${report?.links?.download ? `| <a href="${report?.links?.download}" target="_blank">PDF Rapport</a>` : ''}
        </div>
      </div>
    </div>`);
  }

  protected async loadReport(id: string) {
    const response = await new Report().refactor()
      .dmz(organizationUuid.img)
      .include([
        'organization',
        'type',
        'finance',
        'answers',
        'address',
        'expert',
        'second_expert',
        // 'organization_tc',
        // 'organization_expert',
        'links',
      ])
      .find(id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    return response || null;
  }

  protected get isDisabled(): boolean {
    return ! this.fromDate.selectedDate || ! this.toDate.selectedDate || ! this.magnitude;
  }

  protected get dialogOptionsCreated(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Trillings pdf is aangemaakt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Oke',
        },
      },
    };
  }
}

export interface Gasfield {
  name: string;
  contour: string[];
}
