var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "div",
        { staticClass: "elementPanel" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: {
                          flat: "",
                          type: "spinner--center",
                          minHeight: "300px"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          !_vm.isLoading
            ? [
                !_vm.isLoading
                  ? _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs3: "" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "dialog",
                                attrs: {
                                  "close-on-content-click": false,
                                  lazy: "",
                                  "nudge-right": "100",
                                  "full-width": "",
                                  "max-width": "290px",
                                  "min-width": "290px"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "input input-date",
                                  attrs: {
                                    slot: "activator",
                                    placeholder: "DD-MM-YYYY",
                                    label: "Datum vanaf",
                                    clearable: "",
                                    "error-messages": _vm.fromDate.errorMessage,
                                    "hide-details": ""
                                  },
                                  on: {
                                    "click:clear": function($event) {
                                      return _vm.fromDate.clear()
                                    }
                                  },
                                  slot: "activator",
                                  model: {
                                    value: _vm.fromDate.formattedDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.fromDate,
                                        "formattedDate",
                                        $$v
                                      )
                                    },
                                    expression: "fromDate.formattedDate"
                                  }
                                }),
                                _c("v-date-picker", {
                                  ref: "picker",
                                  attrs: {
                                    "first-day-of-week": "1",
                                    locale: "nl-nl",
                                    min: "1910-01-01",
                                    color: "#837f16"
                                  },
                                  model: {
                                    value: _vm.fromDate.selectedDate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.fromDate,
                                        "selectedDate",
                                        $$v
                                      )
                                    },
                                    expression: "fromDate.selectedDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs3: "" } },
                          [
                            _c(
                              "v-menu",
                              {
                                ref: "dialog",
                                attrs: {
                                  "close-on-content-click": false,
                                  lazy: "",
                                  "nudge-right": "100",
                                  "full-width": "",
                                  "max-width": "290px",
                                  "min-width": "290px"
                                }
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "input input-date",
                                  attrs: {
                                    slot: "activator",
                                    placeholder: "DD-MM-YYYY",
                                    label: "Datum t/m",
                                    clearable: "",
                                    "error-messages": _vm.toDate.errorMessage,
                                    "hide-details": ""
                                  },
                                  on: {
                                    "click:clear": function($event) {
                                      return _vm.toDate.clear()
                                    }
                                  },
                                  slot: "activator",
                                  model: {
                                    value: _vm.toDate.formattedDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.toDate, "formattedDate", $$v)
                                    },
                                    expression: "toDate.formattedDate"
                                  }
                                }),
                                _c("v-date-picker", {
                                  ref: "picker",
                                  attrs: {
                                    "first-day-of-week": "1",
                                    locale: "nl-nl",
                                    min: "1910-01-01",
                                    color: "#837f16"
                                  },
                                  model: {
                                    value: _vm.toDate.selectedDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.toDate, "selectedDate", $$v)
                                    },
                                    expression: "toDate.selectedDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs2: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "hide-details": "",
                                label: "Minimale magnitude"
                              },
                              model: {
                                value: _vm.magnitude,
                                callback: function($$v) {
                                  _vm.magnitude = $$v
                                },
                                expression: "magnitude"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            "hide-details": "",
                            label: "Alleen aardbevingen uit Groninger gasveld"
                          },
                          model: {
                            value: _vm.earthquakesGroningerOnly,
                            callback: function($$v) {
                              _vm.earthquakesGroningerOnly = $$v
                            },
                            expression: "earthquakesGroningerOnly"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Toon gebieden", color: "primary" },
                          on: {
                            change: function($event) {
                              return _vm.toggleGasfields()
                            }
                          },
                          model: {
                            value: _vm.showGasfields,
                            callback: function($$v) {
                              _vm.showGasfields = $$v
                            },
                            expression: "showGasfields"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  { attrs: { "justify-space-between": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "v-layout",
                          [
                            _c(
                              "v-flex",
                              { attrs: { shrink: "" } },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      icon: "move_to_inbox",
                                      disabled: _vm.isDisabled,
                                      color: "secondary",
                                      compact: "true"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.downloadFile($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" Download pdf ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { shrink: "" } },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      icon: "picture_as_pdf",
                                      disabled: _vm.isDisabled,
                                      color: "primary",
                                      compact: "true"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.generateAttachment($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" Genereer bijlage ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "MiButton",
                          {
                            attrs: {
                              icon: "landscape",
                              disabled: _vm.isDisabled,
                              color: "primary",
                              compact: "true",
                              outline: "true"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.initializeEarthquakes($event)
                              }
                            }
                          },
                          [_vm._v(" Genereer Map ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                !_vm.isLoading && _vm.showMap
                  ? [
                      _c("div", { staticClass: "separator my-3" }),
                      _c(
                        "div",
                        [
                          _vm.isLoading
                            ? _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center",
                                          minHeight: "300px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isLoading
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c(
                                        "gmap-map",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            height: "400px"
                                          },
                                          attrs: {
                                            center: {
                                              lat: _vm.address.lat,
                                              lng: _vm.address.lng
                                            },
                                            zoom: 18
                                          }
                                        },
                                        [
                                          _c("GmapMarker", {
                                            attrs: {
                                              position: {
                                                lat: _vm.address.lat,
                                                lng: _vm.address.lng
                                              },
                                              clickable: true,
                                              draggable: true
                                            }
                                          }),
                                          _vm._l(_vm.reports, function(
                                            report,
                                            reportIndex
                                          ) {
                                            return _c("GmapMarker", {
                                              key: "report-" + reportIndex,
                                              attrs: {
                                                position: report.position,
                                                draggable: false,
                                                clickable: true,
                                                icon: _vm.getMarkerColor(
                                                  report
                                                ),
                                                title: report.name
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleInfoWindow(
                                                    report,
                                                    reportIndex
                                                  )
                                                }
                                              }
                                            })
                                          }),
                                          _c(
                                            "GmapInfoWindow",
                                            {
                                              attrs: {
                                                options: _vm.infoOptions,
                                                position: _vm.infoWindowPos,
                                                opened: _vm.infoWinOpen
                                              },
                                              on: {
                                                closeclick: function($event) {
                                                  _vm.infoWinOpen = false
                                                }
                                              }
                                            },
                                            [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.infoContent
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c(
                                        "gmap-map",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            height: "400px"
                                          },
                                          attrs: {
                                            center: _vm.mapCenter,
                                            zoom: 9
                                          }
                                        },
                                        [
                                          _c("GmapMarker", {
                                            attrs: {
                                              position: {
                                                lat: _vm.address.lat,
                                                lng: _vm.address.lng
                                              },
                                              clickable: true,
                                              draggable: true
                                            }
                                          }),
                                          _vm._l(_vm.gasfields6Km, function(
                                            gasfield
                                          ) {
                                            return _c("GmapPolygon", {
                                              key: gasfield.id,
                                              attrs: {
                                                paths: gasfield.contour,
                                                visible: true,
                                                options: {
                                                  strokeOpacity: 0.8,
                                                  strokeWeight: 2,
                                                  fillColor: "#a09b1b",
                                                  fillOpacity: 0.3
                                                }
                                              }
                                            })
                                          }),
                                          _vm._l(_vm.gasfields, function(
                                            gasfield
                                          ) {
                                            return _c("GmapPolygon", {
                                              key: gasfield.id,
                                              attrs: {
                                                paths: gasfield.contour,
                                                visible: true,
                                                options: {
                                                  strokeOpacity: 0.8,
                                                  strokeWeight: 2,
                                                  fillColor: "#a09b1b",
                                                  fillOpacity: 0.3
                                                }
                                              }
                                            })
                                          }),
                                          _vm._l(_vm.earthquakes, function(
                                            earthquake,
                                            earthquakeRadiusIndex
                                          ) {
                                            return _c("GmapCircle", {
                                              key:
                                                "earthquakeRadius-" +
                                                earthquakeRadiusIndex,
                                              attrs: {
                                                center: earthquake.center,
                                                radius: earthquake.radius,
                                                visible: true,
                                                options: _vm.getCircleOptions(
                                                  earthquake
                                                ),
                                                clickable: false
                                              }
                                            })
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _vm.report
                          ? _c("DataTable", {
                              ref: "trillingstoolDatatable",
                              attrs: {
                                options: _vm.tableOptions,
                                visibility: _vm.visibility,
                                limit: 10
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("v-flex", [
                      _c("p", [
                        _vm._v(
                          "*) Berekende maximale trillingssnelheid in mm/s (overschrijdingskans 50%)"
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "**) Berekende maximale trillingssnelheid in mm/s (overschrijdingskans 25%)"
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "***) Berekende maximale trillingssnelheid in mm/s (overschrijdingskans 1%)"
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }